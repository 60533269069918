import 'owl.carousel';
import 'jquery';
import 'tw-elements';


import {
    Collapse,
    Dropdown,
    initTE,
} from "tw-elements";

import 'lightbox2';

initTE({ Collapse, Dropdown });

var jQuery = require('jquery');

jQuery(document).ready(function () {
    jQuery('.owl-carousel').each(function (index) {
        let carousel = jQuery(this);
        let breakpoints = {'items': 0, 'items-sm': 640, 'items-md': 768, 'items-lg': 1024, 'items-xl': 1280, 'items-2xl': 1536};
        let responsive = Object.entries(breakpoints).reduce((carry, [step, width]) => {
            if (carousel.data(step)) {
                carry[width] = {items: carousel.data(step)};
            }
            return carry;
        }, {0: {items: 1}});
        let options = {
            loop: true,
            margin: 40,
            nav: carousel.data('nav') !== undefined ? carousel.data('nav') : true,
            autoplay: carousel.data('autoplay'),
            autoplaySpeed: 1000,
            autoplayHoverPause: true,
            dots: carousel.data('dots'),
            navText: ["<i class=\"fa-solid fa-chevron-left\"></i>", "<i class=\"fa-solid fa-chevron-right\"></i>"],
            responsive: responsive,
            navContainerClass: carousel.data('nav-container-class'),
            navContainer: carousel.data('nav-container'),
            autoHeight: carousel.data('auto-height') && true,
        }
        carousel.owlCarousel(options)
    });
});

window.twttr = (function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0],
    t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function(f) {
    t._e.push(f);
};

    return t;
}(document, "script", "twitter-wjs"));


document.querySelectorAll('[data-social-button]').forEach(button => {
    button.addEventListener('click', event => {
        event.preventDefault();
        window.open(button.href, '_blank', 'popup,width=400,height=400');
    })
})
